import { RouteConfig } from 'vue-router';
import { isAuth } from '@/helpers/auth';

const routes: Array<RouteConfig> = [
  {
    path: '/ai-topics',
    name: 'AITopics',
    component: () => import('@/views/AITopics/index.vue'),
    beforeEnter: (to, from, next) => {
      isAuth() ? next() : next({ path: '/auth' });
    },
  },
];

export default routes;
