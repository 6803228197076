import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import gql from 'graphql-tag';
import store from '@/store';

export const isAuth = (): boolean => store.getters['auth/loggedIn'];

export const isAuthBusiness = async (apolloClient: ApolloClient<NormalizedCacheObject>, id?: string): Promise<any> => {
  let data = null;

  if (id) {
    try {
      data = await apolloClient.query({
        query: gql`
          query organization($id: ID!) {
            organization(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      });
    } catch {
      data = null;
    }
  }

  return Promise.resolve(data);
};
