import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/reports',
    name: 'Reports',
    meta: {
      title: 'Reports',
    },
    component: () => import('../views/Reports.vue'),
  },
];

export default routes;
