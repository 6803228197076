import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../types';
import { TopicStage } from '@/store/topic/types';
import generateRandomSeed from '@/store/topic/utils/generateRandomSeed';

const state: TopicStage = {
  countdown: 0,
  questionId: '',
  agreeWithPolicy: false,
  isMicrophoneAvailable: false,
  isTopicSent: false,
  randomSeed: generateRandomSeed(),
};

const actions: ActionTree<TopicStage, RootState> = {
  GET_RANDOM_SEED: ({ commit }): number => {
    const seed = generateRandomSeed();
    commit('SET_RANDOM_SEED', seed);

    return seed;
  },
};

const mutations: MutationTree<TopicStage> = {
  SET_QUESTION_DATA: (state, { id, countdown }: { id: string; countdown: number }): void => {
    state.questionId = id;
    state.countdown = countdown;
  },
  SET_POLICY: (state, agreeWithPolicy: boolean): void => {
    state.agreeWithPolicy = agreeWithPolicy;
  },
  SET_MICROPHONE_STATUS: (state, isMicrophoneAvailable: boolean): void => {
    state.isMicrophoneAvailable = isMicrophoneAvailable;
  },
  SET_TOPIC_SENT_STATUS: (state, isTopicSent: boolean): void => {
    state.isTopicSent = isTopicSent;
  },
  SET_RANDOM_SEED: (state, seed: number): void => {
    state.randomSeed = seed;
  },
};

const getters: GetterTree<TopicStage, RootState> = {
  GET_RANDOM_SEED(state): number {
    return state.randomSeed;
  },
};

const index: Module<TopicStage, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
