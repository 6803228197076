export default function getPluginContainer() {
  const selector = 'body';

  const section = document.querySelector(selector) as HTMLElement;
  if (!section) {
    throw Error('Dom element "body" should be in the DOM');
  }

  const node = document.createElement('div') as HTMLElement;
  node.className = 'v-application';

  section.appendChild(node);

  return document.querySelector('.v-application') as HTMLElement;
}
