import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { ClientSide, ProgressData, TestScore } from '@/views/Dashboard/views/index/types';
import {
  activeVocabulareOptions,
  DATE_FORMATTER,
  desityOfSpeechErrorsOptions,
  IELTSOptions,
  optionsConfidence,
  optionsRecords,
  wordsPerMinuteOptions,
  generalProgress,
  pronunciationErrorsDensity,
} from '@/views/Dashboard/views/Progress/utils/charts';
import { formatCommonChartData, formatRadarChartData, formatRadarChartLabelData } from '@/store/auth/utils';

const state: ClientSide = {
  reportList: [],
  progressReports: [],
  optionsConfidence: {},
  activeVocabulare: {},
  IELTSOptions: {},
  optionsRecords: {},
  wordsPerMinuteOptions: {},
  pronunciationErrorsDensity: {},
  densityOfSpeechErrorsOptions: {},
  generalRadarChart: {},
  scoreFluency: [],
  scoreGrammar: [],
  scoreInteraction: [],
  scorePronunciation: [],
  scoreVocabulary: [],
};

const getters: GetterTree<ClientSide, RootState> = {
  progressReports(state) {
    return state.progressReports;
  },

  wordsPerMinuteOptions(state) {
    return state.wordsPerMinuteOptions;
  },

  activeVocabulare(state) {
    return state.activeVocabulare;
  },

  densityOfSpeechErrorsOptions(state) {
    return state.densityOfSpeechErrorsOptions;
  },

  optionsRecordsDuration(state) {
    return state.optionsRecords;
  },

  IELTSOptions(state) {
    return state.IELTSOptions;
  },

  optionsConfidence(state) {
    return state.optionsConfidence;
  },

  generalRadarChart(state) {
    return state.generalRadarChart;
  },

  detailedProgressData(state) {
    return {
      fluency: state.scoreFluency,
      grammar: state.scoreGrammar,
      interaction: state.scoreInteraction,
      pronunciation: state.scorePronunciation,
      vocabulary: state.scoreVocabulary,
    };
  },

  pronunciationErrorsDensity(state) {
    return state.pronunciationErrorsDensity;
  },
};

const mutations: MutationTree<ClientSide> = {
  SET_REPORT_LIST: (state, reportList: ProgressData[]): void => {
    state.progressReports = reportList;
  },

  SET_RADAR_CHART: (state, reportList: ProgressData[]): void => {
    const filteredData = reportList.filter((r) => r.test_score);

    if (filteredData.length) {
      state.generalRadarChart = {
        ...generalProgress,
        series: [
          {
            name: 'First Report',
            data: formatRadarChartData(filteredData[0].test_score as TestScore),
          },
          {
            name: 'Last Report',
            data: formatRadarChartData(filteredData[filteredData.length - 1].test_score as TestScore),
          },
        ],
        labels: formatRadarChartLabelData(filteredData[0].test_score as TestScore),
      };
    }
  },

  SET_FLUENCY_METRICS: (state, reportList: ProgressData[]): void => {
    const filteredData = reportList.filter((r) => r.test_score);
    const scoreFluency: { x: string[]; y: number | undefined }[] = [];
    const scoreGrammar: { x: string[]; y: number | undefined }[] = [];
    const scoreInteraction: { x: string[]; y: number | undefined }[] = [];
    const scorePronunciation: { x: string[]; y: number | undefined }[] = [];
    const scoreVocabulary: { x: string[]; y: number | undefined }[] = [];

    filteredData.forEach((item) => {
      scoreFluency.push({
        x: DATE_FORMATTER(item.created),
        y: item.test_score?.fluency,
      });
      scoreGrammar.push({
        x: DATE_FORMATTER(item.created),
        y: item.test_score?.grammar,
      });
      scoreInteraction.push({
        x: DATE_FORMATTER(item.created),
        y: item.test_score?.interaction,
      });
      scorePronunciation.push({
        x: DATE_FORMATTER(item.created),
        y: item.test_score?.pronunciation,
      });
      scoreVocabulary.push({
        x: DATE_FORMATTER(item.created),
        y: item.test_score?.vocabulary,
      });
    });

    state.scoreFluency = scoreFluency.filter((g) => g.y);
    state.scoreGrammar = scoreGrammar.filter((g) => g.y);
    state.scoreInteraction = scoreInteraction.filter((g) => g.y);
    state.scorePronunciation = scorePronunciation.filter((g) => g.y);
    state.scoreVocabulary = scoreVocabulary.filter((g) => g.y);
  },

  SET_CONFIDENCE: (state, reportList: ProgressData[]): void => {
    state.optionsConfidence = {
      ...optionsConfidence,
      series: [
        {
          name: 'Confidence report',
          data: formatCommonChartData(reportList, 'confidence'),
        },
      ],
    };
  },

  SET_IELTS_BAND: (state, reportList: ProgressData[]): void => {
    state.IELTSOptions = {
      ...IELTSOptions,
      series: [
        {
          name: 'IELTS report',
          data: formatCommonChartData(reportList, 'ielts_band'),
        },
      ],
    };
  },

  SET_DURATION: (state, reportList: ProgressData[]): void => {
    state.optionsRecords = {
      ...optionsRecords,
      series: [
        {
          name: 'Duration report',
          data: formatCommonChartData(reportList, 'duration'),
        },
      ],
    };
  },

  SET_GRAMMAR_ERRORS_DENSITY: (state, reportList: ProgressData[]): void => {
    state.densityOfSpeechErrorsOptions = {
      ...desityOfSpeechErrorsOptions,
      series: [
        {
          name: 'Density report',
          data: formatCommonChartData(reportList, 'grammar_errors_density'),
        },
      ],
    };
  },

  SET_WORDS_PER_MINUTE: (state, reportList: ProgressData[]): void => {
    state.wordsPerMinuteOptions = {
      ...wordsPerMinuteOptions,
      series: [
        {
          name: 'Words per minute report',
          data: formatCommonChartData(reportList, 'words_per_minute'),
        },
      ],
    };
  },

  SET_ACTIVE_VOCABULARY: (state, reportList: ProgressData[]): void => {
    state.activeVocabulare = {
      ...activeVocabulareOptions,
      series: [
        {
          name: 'Vocab report',
          data: formatCommonChartData(reportList, 'active_vocabulary'),
        },
      ],
    };
  },

  SET_PRONUNCIATION_ERRORS_DENSITY: (state, reportList: ProgressData[]): void => {
    state.pronunciationErrorsDensity = {
      ...pronunciationErrorsDensity,
      series: [
        {
          name: 'Pron report',
          data: formatCommonChartData(reportList, 'pronunciation_errors_density'),
        },
      ],
    };
  },
};

const actions: ActionTree<ClientSide, RootState> = {};

const index: Module<ClientSide, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
