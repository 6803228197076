import { RouteConfig } from 'vue-router/types/router';

const routes: Array<RouteConfig> = [
  {
    path: '/cert/:id',
    name: 'CertificatePage',
    props: true,
    component: () => import('../views/index.vue'),
  },
];

export default routes;
