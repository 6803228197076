import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/policy/',
    component: () => import('@/views/Legal/index.vue'),
    children: [
      {
        path: 'privacy',
        redirect: { name: 'privacy_policy' },
      },
      {
        path: 'terms',
        redirect: { name: 'terms' },
      },
      {
        path: 'cookies',
        redirect: { name: 'cookie_policy' },
      },
      {
        path: 'data_deletion',
        redirect: { name: 'data_deletion' },
      },
    ],
  },
  {
    path: '/legal/',
    component: () => import('@/views/Legal/index.vue'),
    children: [
      {
        name: 'terms',
        path: 'terms',
        component: () => import('@/views/Legal/components/Terms2.vue'),
      },
      {
        path: 'terms/archive/0',
        component: () => import('@/views/Legal/components/Terms0.vue'),
      },
      {
        path: 'terms/archive/1',
        component: () => import('@/views/Legal/components/Terms1.vue'),
      },
      {
        path: 'privacy/archive/0',
        component: () => import('@/views/Legal/components/Privacy0.vue'),
      },
      {
        path: 'privacy/archive/1',
        component: () => import('@/views/Legal/components/Privacy1.vue'),
      },
      {
        name: 'privacy_policy',
        path: 'privacy',
        component: () => import('@/views/Legal/components/Privacy2.vue'),
      },
      {
        name: 'cookie_policy',
        path: 'cookie',
        component: () => import('@/views/Legal/components/Cookies.vue'),
      },
      {
        name: 'data_deletion',
        path: 'data_deletion',
        component: () => import('@/views/Legal/components/DataDeletion.vue'),
      },
    ],
  },
];

export default routes;
