import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/org/auth/',
    name: 'OrganizationAuth',
    component: () => import(/* OrganizationAuth: "business" */ '@/views/Organizations/Auth/views/Auth.vue'),
    children: [
      {
        path: '',
        name: 'OrganizationAuthSignin',
        component: () =>
          import(/* OrganizationAuthSignin: "business" */ '@/views/Organizations/Auth/components/Login.vue'),
      },
      {
        path: 'signup',
        name: 'OrganizationAuthSignup',
        component: () =>
          import(/* OrganizationAuthSignin: "business" */ '@/views/Organizations/Auth/components/Signup.vue'),
      },
    ],
  },
  {
    path: '/org/auth/restore',
    name: 'OrganizationAuthPasswordRestore',
    component: () =>
      import(/* OrganizationAuthPasswordRestore: "business" */ '@/views/Organizations/Auth/views/Restore.vue'),
  },
];

export default routes;
