import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from '@/store/auth';
import layout from '@/store/layout';
import topic from '@/store/topic';
import course from '@/store/course';
import dashboard from '@/store/dashboard';
import reports from '@/store/reports';
import business from '@/store/business';
import clientSide from '@/views/Dashboard/store';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        // 'auth.token_state',
        // 'auth.user',
        'layout.drawer',
        'layout.theme',
        'layout.invitationClosedUserID',
        'layout.shareFeedbackClosedID',
        'business.company',
        'business.variantList',
        'business.isShowVideoInstruction',
        'topic.countdown',
        'topic.questionId',
        'topic.agreeWithPolicy',
        'topic.isMicrophoneAvailable',
        'topic.isTopicSent',
        'topic.randomSeed',
        'course.instructionClosedIds',
        'dashboard.usages',
        // 'dashboard.assessment',
        'dashboard.minOfRecord',
        'dashboard.freeMinOfRecord',
        'dashboard.ielts',
        'dashboard.featured',
        'dashboard.courses',
        'dashboard.newYearChallenge',
        'dashboard.newYearFolder',
        // 'dashboard.passed_assessment',
        'reports.reports',
        'assessment.logo',
        'assessment.color',
        'clientSide.reportList',
        'clientSide.progressReports',
        'clientSide.optionsConfidence',
        'clientSide.activeVocabulare',
        'clientSide.IELTSOptions',
        'clientSide.optionsRecords',
        'clientSide.wordsPerMinuteOptions',
        'clientSide.pronunciationErrorsDensity',
        'clientSide.densityOfSpeechErrorsOptions',
        'clientSide.generalRadarChart',
        'clientSide.scoreFluency',
        'clientSide.scoreGrammar',
        'clientSide.scoreInteraction',
        'clientSide.scorePronunciation',
        'clientSide.scoreVocabulary',
      ],
    }),
  ],

  modules: {
    auth,
    layout,
    topic,
    course,
    dashboard,
    reports,
    business,
    clientSide,
  },
});
