<template>
  <div class="notifications-wrapper">
    <transition-group name="list" tag="div">
      <div v-for="notification in notifications" :key="notification.id" class="notification-wrapper list-item">
        <MessageBox :id="notification.id" :timestamp="notification.timestamp" :action="notification.action" />
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import MessageBox from '../../../../components/MessageBox/MessageBox.vue';

const vm = new Vue({
  data() {
    return {
      notifications: [],
    };
  },
});

@Component({ components: { MessageBox } })
export default class NotificationService extends Vue {
  get notifications() {
    return vm.$data.notifications;
  }

  push(notification: object) {
    vm.notifications.push(notification);
  }

  resolve(id: string) {
    vm.notifications = [...vm.notifications.filter((notification) => notification.id !== id)];
  }
}
</script>

<style src="./NotificationService.scss" scoped lang="scss" />
