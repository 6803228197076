import moment from 'moment';

const TOOLTIP_FORMATTER = ({
  series,
  seriesIndex,
  dataPointIndex,
  w,
}: {
  series: any;
  seriesIndex: any;
  dataPointIndex: any;
  w: any;
}) => `<div class='tool_box'>
          <p>Value: ${series[seriesIndex][dataPointIndex]}</p>
          <p>Date time: ${w.globals.categoryLabels[dataPointIndex] || w.globals.labels[dataPointIndex]}</p>
          </div>`;
export const DATE_FORMATTER = (val: string | number | Date) => moment(new Date(val)).format('DD MMM  H:mm').split('  ');

const activeVocabulareOptions = {
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  series: [
    {
      name: 'Vocabulare report',
      data: [] as { x: string; y: number | null }[],
    },
  ],
  chart: {
    stacked: false,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    colors: ['#82A4FF'],
    width: 1,
  },
  markers: {
    size: 2,
    colors: ['#2E68FF'],
    strokeColors: '#2E68FF',
    strokeWidth: 1,
  },
  fill: {
    colors: ['#82A4FF'],
  },
  xaxis: {
    type: 'category',
    labels: {
      showDuplicates: true,
      rotate: 0,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    title: {
      text: 'words',
      rotate: -90,
      offsetX: 0,
      offsetY: 10,
      style: {
        color: '#606070',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
  },
  responsive: [
    {
      breakpoint: 375,
      width: '100vw',
      options: {
        plotOptions: {},
      },
    },
  ],
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
};
const optionsRecords = {
  id: 'Records',
  dataLabels: {
    enabled: false,
  },
  chart: {
    toolbar: {
      show: false,
    },
  },
  stroke: {
    colors: ['#82A4FF'],
    width: 1,
  },
  fill: {
    colors: ['#82A4FF'],
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.3,
    },
    pattern: {
      strokeWidth: 1,
    },
  },
  xaxis: {
    type: 'numeric',
    axisTicks: {
      show: false,
    },
    labels: {
      showDuplicates: true,
      rotate: 0,
      style: {
        color: '#606070',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    title: {
      text: 'minutes',
      rotate: -90,
      offsetX: 0,
      offsetY: 10,
      style: {
        color: '#606070',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
  },
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
};
const optionsConfidence = {
  id: 'Confidence',
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  chart: {
    height: 240,
    stacked: false,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    colors: ['#82A4FF'],
    width: 1,
  },
  markers: {
    size: 2,
    colors: ['#2E68FF'],
    strokeColors: '#2E68FF',
    strokeWidth: 1,
  },
  fill: {
    colors: ['#82A4FF'],
  },
  dataLabels: {
    enabled: false,
    style: {
      colors: ['#606070'],
    },
  },
  xaxis: {
    type: 'category',
    axisTicks: {
      show: false,
    },
    overwriteCategories: [],
    labels: {
      show: false,
      rotate: 0,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#1B1F2E',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 375,
      width: '100vw',
      options: {
        chart: {
          height: 170,
        },
        plotOptions: {},
      },
    },
  ],
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
};
const varietyVocabulary = [
  ['Your Word', 'Suggestion'],
  ['Your Word', 'Suggestion'],
  ['Your Word', 'Suggestion'],
  ['Your Word', 'Suggestion'],
  ['Your Word', 'Suggestion'],
];
const generalProgress = {
  id: 'Progress',
  chart: {
    toolbar: {
      show: false,
    },
  },
  markers: {
    size: 0,
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  plotOptions: {
    radar: {
      polygons: {
        strokeColor: '#e8e8e8',
        fill: {
          colors: ['#f8f8f8'],
        },
      },
    },
  },
  xaxis: {
    labels: {
      show: true,
      offsetY: -15,
      style: {
        colors: ['#606070', '#606070', '#606070', '#606070', '#606070'],
        fontSize: '12px',
        fontWeight: '500',
        fontFamily: 'Roboto',
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  fill: {
    opacity: 0.4,
    colors: ['#FECA57', '#2E68FF'],
  },
  stroke: {
    show: true,
    colors: ['#FECA57', '#2E68FF'],
    width: 2,
  },
};
const IELTSOptions = {
  id: 'IELTS',
  chart: {
    height: 240,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    gradient: {
      opacityFrom: 0,
      opacityTo: 0,
    },
  },
  stroke: {
    curve: 'straight',
    stacked: true,
    colors: ['#2E68FF'],
    width: 1,
  },
  yaxis: {
    show: false,
    min: 1,
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ['#606070'],
    },
  },
  xaxis: {
    type: 'category',
    overwriteCategories: [],
    labels: {
      show: false,
      rotate: 0,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#1B1F2E',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 375,
      width: '100vw',
      options: {
        chart: {
          height: 170,
        },
        plotOptions: {},
      },
    },
  ],
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
};
const wordsPerMinuteOptions = {
  id: 'wordsPerMinuteOptions',
  dataLabels: {
    enabled: false,
  },
  chart: {
    height: 240,
    stacked: false,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    colors: ['#82A4FF'],
    width: 1,
  },
  markers: {
    size: 2,
    colors: ['#2E68FF'],
    strokeColors: '#2E68FF',
    strokeWidth: 1,
  },
  fill: {
    colors: ['#82A4FF'],
  },
  xaxis: {
    type: 'category',
    overwriteCategories: [],
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      rotate: 0,
      style: {
        color: '#606070',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
  responsive: [
    {
      breakpoint: 375,
      width: '100vw',
      options: {
        chart: {
          height: 170,
        },
        plotOptions: {},
      },
    },
  ],
};
const desityOfSpeechErrorsOptions = {
  id: 'desityOfSpeechErrors',
  dataLabels: {
    enabled: false,
  },
  chart: {
    height: 240,
    stacked: false,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    colors: ['#82A4FF'],
    width: 1,
  },
  markers: {
    size: 2,
    colors: ['#2E68FF'],
    strokeColors: '#2E68FF',
    strokeWidth: 1,
  },
  fill: {
    colors: ['#82A4FF'],
  },
  xaxis: {
    type: 'numeric',
    overwriteCategories: [],
    labels: {
      show: false,
      rotate: 0,
      style: {
        color: '#606070',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
  responsive: [
    {
      breakpoint: 375,
      width: '100vw',
      options: {
        chart: {
          height: 170,
        },
        plotOptions: {},
      },
    },
  ],
};
const pronunciationErrorsDensity = {
  id: 'pronunciationErrors',
  dataLabels: {
    enabled: false,
  },
  chart: {
    height: 240,
    stacked: false,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    colors: ['#82A4FF'],
    width: 1,
  },
  fill: {
    colors: ['#82A4FF'],
  },
  xaxis: {
    type: 'category',
    overwriteCategories: [],
    labels: {
      show: false,
      rotate: 0,
      style: {
        color: '#606070',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
  responsive: [
    {
      breakpoint: 375,
      width: '100vw',
      options: {
        chart: {
          height: 170,
        },
        plotOptions: {},
      },
    },
  ],
  markers: {
    size: 2,
    colors: ['#2E68FF'],
    strokeColors: '#2E68FF',
    strokeWidth: 1,
  },
};
const detailedProgressOptions = {
  series: [
    {
      name: 'detailedProgress report',
      data: [] as { x: string; y: number | null }[],
    },
  ],
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    gradient: {
      opacityFrom: 0,
      opacityTo: 0,
    },
  },
  stroke: {
    curve: 'straight',
    stacked: true,
    colors: ['#2E68FF'],
    width: 1,
  },
  yaxis: {
    show: false,
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    style: {
      colors: ['#606070'],
    },
  },
  xaxis: {
    type: 'category',
    labels: {
      showDuplicates: true,
      rotate: 0,
      style: {
        color: '#1B1F2E',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
};
const emptyDataChartMap = {
  series: [
    {
      name: 'emptyDataChartMap',
      data: [
        {
          x: DATE_FORMATTER(new Date('2020.01.02')),
          y: 10,
        },
        {
          x: DATE_FORMATTER(new Date('2020.01.03')),
          y: 20,
        },
        {
          x: DATE_FORMATTER(new Date('2020.01.04')),
          y: 15,
        },
        {
          x: DATE_FORMATTER(new Date('2020.01.05')),
          y: 30,
        },
        {
          x: DATE_FORMATTER(new Date('2020.01.6')),
          y: 20,
        },
      ],
    },
  ],
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  fill: {
    gradient: {
      opacityFrom: 0,
      opacityTo: 0,
    },
  },
  stroke: {
    curve: 'straight',
    stacked: true,
    colors: ['#2E68FF'],
    width: 1,
  },
  yaxis: {
    show: false,
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    style: {
      colors: ['#606070'],
    },
  },
  xaxis: {
    type: 'category',
    labels: {
      showDuplicates: true,
      rotate: 0,
      style: {
        color: '#1B1F2E',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
  },
  tooltip: {
    custom: TOOLTIP_FORMATTER,
  },
};
const emptyRadarChart = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  markers: {
    size: 0,
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    borderColor: '#F5F5F5',
    clipMarkers: false,
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  series: [
    {
      data: [80, 50, 30, 40, 100, 20],
    },
    {
      data: [20, 30, 40, 80, 20, 80],
    },
    {
      data: [44, 76, 78, 13, 43, 10],
    },
    {
      data: [44, 76, 74, 20, 55, 53],
    },
    {
      data: [50, 76, 76, 15, 60, 10],
    },
  ],
  xaxis: {
    categories: ['Fluency', 'Grammar', 'Pronunciation', 'Interaction', 'Pronunciation', 'Vocabulary'],
    labels: {
      style: {
        colors: ['#606070'],
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'Roboto',
      },
    },
  },
  fill: {
    colors: ['#2effe0', '#FECA57', '#a2fe57', '#9a2eff', '#2E68FF'],
  },
  stroke: {
    colors: ['#2effe0', '#FECA57', '#a2fe57', '#9a2eff', '#2E68FF'],
    width: 2,
  },
};

export {
  activeVocabulareOptions,
  optionsRecords,
  optionsConfidence,
  varietyVocabulary,
  generalProgress,
  IELTSOptions,
  wordsPerMinuteOptions,
  emptyDataChartMap,
  desityOfSpeechErrorsOptions,
  detailedProgressOptions,
  emptyRadarChart,
  pronunciationErrorsDensity,
};
