<template>
  <div class="snack-wrap">
    <Snackbar
      v-for="(propsData, index) in snackbars"
      :id="propsData.id"
      :key="index"
      :props-data="propsData"
      @remove="removeSnack"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Snackbar from './Snackbar.vue';

@Component({
  components: {
    Snackbar,
  },
})
export default class Wrapper extends Vue {
  snackbars: {
    id: number | undefined;
  }[] = [];

  removeSnack(id: number) {
    const index = this.snackbars.findIndex((s) => s.id === id);
    if (index !== -1) this.snackbars.splice(index, 1);
  }

  commit(snackbar: { id: number | undefined }): void {
    snackbar.id = Math.random();
    this.snackbars.push(snackbar);
  }
}
</script>

<style scoped>
.snack-wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
}
</style>
