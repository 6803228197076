import moment from 'moment';
import { Token, TokenState } from '@/store/auth/types';
import { TOKEN_STORAGE_KEY } from '@/store/CONSTANTS';
import { ProgressData, TestScore } from '@/views/Dashboard/views/index/types';
import { levels } from '@/utils/constants/skill-level';

function now(): number {
  return Math.round(Date.now() / 1000);
}

function get_token_state(token: Token): TokenState {
  return {
    access_token: token.access_token,
    access_token_expires_in: now() + token.expires_in,
    refresh_token: token.refresh_token,
    has_user: token.has_user,
  };
}

function loadTokenState(): TokenState | null {
  try {
    const data: string | null = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (data == null) {
      return null;
    }
    const tokenState: TokenState | null = JSON.parse(data);
    if (typeof tokenState === 'object' && tokenState !== null && 'has_user' in tokenState) {
      return tokenState;
    }
    return null;
  } catch (e) {
    return null;
  }
}

function formatRadarChartData(data: TestScore): number[] {
  const { total_score, ...rest } = data;
  return Object.values(rest).map((r) => (r ? (r as number) / 12 : 0.1));
}

function formatRadarChartLabelData(data: TestScore): string[][] {
  const { total_score, ...rest } = data;
  const restValues: { [key: string]: number } = { ...rest };
  const formatted: string[][] = [];

  Object.keys(rest).forEach((key) => {
    const score = key.charAt(0).toUpperCase().concat(key.slice(1));
    formatted.push([score, levels[Math.round(restValues[key])]]);
  });

  return formatted;
}

function formatCommonChartData(data: ProgressData[], key: string) {
  return data
    .filter((r: ProgressData | any) => r[key])
    .map((r: ProgressData | any) => ({
      x: moment(new Date(r.created)).format('DD.MM.YYYY H:mm'),
      y: Math.round(r[key] as number),
    }));
}

export { now, get_token_state, loadTokenState, formatRadarChartData, formatRadarChartLabelData, formatCommonChartData };
