import { RouteConfig } from 'vue-router';
import { isAuth } from '@/helpers/auth';

const routes: Array<RouteConfig> = [
  {
    path: 'progress',
    name: 'Progress',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/admin/progress/:id',
    name: 'ProgressAdminView',
    props: true,
    component: () => import('../views/index.vue'),
  },
  // {
  //   path: '/shared/progress/:id',
  //   name: 'ProgressSharedView',
  //   props: true,
  //   component: () => import('../views/index.vue'),
  // },
];

export default routes;
