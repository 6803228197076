import { AxiosInstance } from 'axios';
import { SendCandidateData } from '@/views/Organizations/Business/types';

export default (axios: AxiosInstance) => ({
  getBusinessAssessment: (organization_id: string, page = 0, size = 1000) =>
    axios.get('/business/assessment', { params: { page, size, organization_id } }),

  exportCsv: (organization_id: string) =>
    axios.get('/business/assessments_export', { responseType: 'blob', params: { organization_id } }),

  postBusinessAssessment: (data: SendCandidateData[]) => axios.post('/business/assessment', data),
});
