import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../types';
import { AuthState, User, TokenState, Token, Experiments } from './types';
import { api } from '@/api/api';
import { get_token_state, now, loadTokenState } from '@/store/auth/utils';
import { TOKEN_STORAGE_KEY } from '@/store/CONSTANTS';

const state: AuthState = {
  user: null,
  token_state: null,
};

let onUserChange = (user: User | null): Promise<any> => Promise.resolve();

export const addOnUserChange = (callback: (user: any) => void) => {
  const prevOnUserChange = onUserChange;
  onUserChange = (user: User | null) => Promise.allSettled([prevOnUserChange(user), callback(user)]);
};

const actions: ActionTree<AuthState, RootState> = {
  init(state) {
    const stored_state = loadTokenState();
    if (stored_state === null) {
      const marketingParams: { [key: string]: string } = {};
      new URLSearchParams(window.location.search).forEach((value, key) => {
        if (key.startsWith('utm_')) {
          marketingParams[key] = value || '';
        }
      });
      marketingParams.ref = document.referrer || '';

      return api.startAnonymSession(marketingParams).then((token) => state.dispatch('setToken', token));
    }
    return state.dispatch('setTokenState', stored_state);
  },
  setToken(state, token: Token) {
    return state.dispatch('setTokenState', get_token_state(token));
  },
  logout(state) {
    return api.startAnonymSession().then((token) => state.dispatch('setToken', token));
  },
  setTokenState(state, token_state: TokenState) {
    state.commit('SET_TOKEN_STATE', token_state);
    // Refreshing user data.
    if (!token_state.access_token || !token_state.has_user) {
      state.commit('SET_USER_DATA', null);
      return onUserChange(null);
    }
    return api.userInfo().then((user) => {
      state.commit('SET_USER_DATA', user);
      return onUserChange(user);
    });
  },
  getAccessToken(state): Promise<string | null> {
    if (!state.state.token_state) {
      return Promise.resolve('');
    }
    if (
      !state.state.token_state.refresh_token ||
      !state.state.token_state.access_token ||
      state.state.token_state.access_token_expires_in > now()
    ) {
      return Promise.resolve(state.state.token_state.access_token || '');
    }
    return api
      .authRefreshToken(state.state.token_state.refresh_token)
      .then((token) => {
        state.commit('SET_TOKEN_STATE', get_token_state(token));
        return token.access_token;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return state.dispatch('logout');
        }
        throw error;
      });
  },
};

const mutations: MutationTree<AuthState> = {
  SET_TOKEN_STATE: (state, token_state: TokenState): void => {
    state.token_state = token_state;
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(state.token_state));
  },
  SET_USER_DATA: (state, userData: User): void => {
    state.user = userData;
  },
};

const getters: GetterTree<AuthState, RootState> = {
  loggedIn(state): boolean {
    return state.token_state !== null && state.token_state.has_user;
  },

  isPaid(state): boolean {
    return state.user?.paid || false;
  },

  user(state): User | null {
    return state.user;
  },

  experiments(state): Experiments | null {
    return state.user?.experiments || null;
  },
};

const index: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
