import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

import { api } from '@/api/api';
import { RootState } from '../types';

const state: unknown = {
  reports: [],
};

const actions: ActionTree<unknown, RootState> = {
  async fetch({ commit }) {
    const { values } = await api.getAudiouploadList();
    commit('reports', values);
  },
};

const mutations: MutationTree<unknown> = {
  reports: (state, payload) => {
    state.reports = payload;
  },
};

const getters: GetterTree<unknown, Array<RootState>> = {
  reports(state): Array<object> {
    return state.reports;
  },
};

const index: Module<unknown, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
