import _Vue from 'vue';

import getPluginContainer from './getContainer';
import SnackbarWrapper from './components/Wrapper.vue';
import { SnackOptions, Config } from './types';

const container = getPluginContainer();

const optionsDefaults = {
  top: true,
  right: true,
  timeout: 3000,
};

export default {
  install(Vue: typeof _Vue, opts = {}) {
    const options = { ...optionsDefaults, ...opts };

    const SnackbarConstructor = Vue.extend(SnackbarWrapper);
    SnackbarConstructor.mixin({
      methods: {},
    });

    const Vm = new SnackbarConstructor();
    const node = document.createElement('div');

    container.appendChild(node);

    const snackbar = Vm.$mount(node);

    const prepareConfig = (config: Config) => {
      if (config.permanent) {
        delete config.permanent;
        config.timeout = -1;
      }

      return config;
    };

    Vue.prototype.$snackbar = {
      success(message: string, snackOptions?: SnackOptions) {
        snackbar.commit(
          prepareConfig({
            ...options,
            ...snackOptions,
            message,
            color: 'success',
          }),
        );
      },

      error(message: string, snackOptions: SnackOptions) {
        snackbar.commit(
          prepareConfig({
            ...options,
            ...snackOptions,
            message,
            color: 'error',
          }),
        );
      },

      info(message: string, snackOptions: SnackOptions) {
        snackbar.commit(
          prepareConfig({
            ...options,
            ...snackOptions,
            message,
            color: 'info',
          }),
        );
      },
    };
  },
};
