import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '../types';
import { CourseState } from '@/store/course/types';

const state: CourseState = {
  instructionClosedIds: [],
};

const actions: ActionTree<CourseState, RootState> = {
  toggleInstructionClosedId({ commit }, payload) {
    commit('instructionClosedIdSetter', payload);
  },
};

const mutations: MutationTree<CourseState> = {
  instructionClosedIdSetter: (state, payload) => {
    if (!state.instructionClosedIds.includes(payload)) state.instructionClosedIds.push(payload);
  },
};

const getters: GetterTree<CourseState, RootState> = {};

const index: Module<CourseState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
