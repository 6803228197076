import { RouteConfig } from 'vue-router';
import { isAuth } from '@/helpers/auth';

const routes: Array<RouteConfig> = [
  {
    path: '/payments/success/subscription',
    name: 'wait_for_subscription_apply',
    component: () => import('@/views/Pricing/Payments/views/success.vue'),
  },
  {
    path: '/payments/redeem',
    name: 'redeem_subscription_token',
    component: () => import('@/views/Pricing/Payments/components/RedeemToken.vue'),
    beforeEnter: (to, from, next) => {
      isAuth() ? next() : next({ name: 'Signup', query: { to: to.fullPath } });
    },
  },
];

export default routes;
