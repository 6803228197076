import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/courses',
    name: 'Courses',
    meta: {
      title: 'Courses',
    },
    component: () => import(/* webpackChunkName: "Course" */ '../views/Course.vue'),
  },
  {
    path: '/course/:id',
    name: 'courseTopic',
    meta: {
      title: 'Course Topic',
    },
    props: true,
    component: () => import(/* webpackChunkName: "SingleCourse" */ '../views/SingleCourse.vue'),
  },
  {
    path: '/challenge',
    name: 'Challenge',
    component: () => import(/* webpackChunkName: "Course" */ '../views/Challenge.vue'),
  },
];

export default routes;
