import { AxiosInstance } from 'axios';
import { ProblemsFoldersInfo } from '@/views/Dashboard/views/index/types';
import { TopicData } from '@/types';

export default (axios: AxiosInstance) => ({
  getProblemsFoldersInfo: (id: string): Promise<ProblemsFoldersInfo> =>
    axios.get(`/problems/folders/${id}/info`).then(({ data }: { data: ProblemsFoldersInfo }) => data),

  getProblemsFoldersRandom: (id: string): Promise<TopicData> =>
    axios.get(`/problems/folders/${id}/random`).then(({ data }: { data: TopicData }) => data),

  getPhotoProctor: (id: string): Promise<TopicData> =>
    axios.get(`/audioupload/${id}/proctoring_artifacts`).then(({ data }: { data: TopicData }) => data),
});
