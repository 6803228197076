import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/testing/:id/:kind',
    name: 'Testing',
    component: () => import('@/views/Testing/views/TestingLayout.vue'),
    props: true,
  },
];

export default routes;
