import { RouteConfig } from 'vue-router';
import { isAuth } from '@/helpers/auth';

const routes: Array<RouteConfig> = [
  {
    path: '/auth/complete',
    name: 'Complete',
    component: () => import('@/views/Auth/components/Complete.vue'),
  },
  {
    path: '/auth',
    component: () => import('@/views/Auth/views/index.vue'),
    beforeEnter: async (to, from, next) => {
      const path = (from.query.to || '/') as string;
      isAuth() ? next(path) : next();
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/views/Auth/components/Login.vue'),
      },
      {
        path: 'signup',
        name: 'Signup',
        component: () => import('@/views/Auth/components/Signup.vue'),
      },
      {
        path: 'restore',
        name: 'Restore',
        component: () => import('@/views/Auth/components/Restore.vue'),
      },
    ],
  },
];

export default routes;
