import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default function setUpSentry(Vue: any, router: any, addOnUserChange: (arg0: (user: any) => void) => void) {
  Sentry.init({
    Vue,
    environment: String(import.meta.env.VITE_BRANCH),
    release: String(import.meta.env.VITE_SOURCE_VERSION),
    dsn: 'https://6a88484be4dd4449a8c9778b090c24d6@o957380.ingest.sentry.io/5906574',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'app.smalltalk2.me', 'app.staging.smalltalk2.me', /^\//],
      }),
    ],
    tracesSampleRate: 0.1,
  });

  addOnUserChange((user) => {
    if (!user) {
      Sentry.configureScope((scope) => scope.setUser(null));
    } else {
      Sentry.setUser({ id: user.id });
    }
  });
}
