import { AxiosInstance } from 'axios';
import { ProgressData } from '@/views/Dashboard/views/index/types';

export default (axios: AxiosInstance) => ({
  getProgressData: (params: Record<string, any>): Promise<ProgressData[]> =>
    axios.get(`/profile/progress`, { params }).then(({ data }: { data: ProgressData[] }) => data),

  getAdminProgressView: (id: string, params: Record<string, any> = {}) =>
    axios.get(`/admin/profile/${id}/progress`, { params }).then(({ data }: { data: ProgressData[] }) => data),

  getSharedProgressView: (id: string, params: Record<string, any> = {}) =>
    axios.get(`/profile/shared_progress/${id}`, { params }).then(({ data }: { data: ProgressData[] }) => data),
});
