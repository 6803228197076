import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/ielts',
    name: 'IELTS',
    meta: {
      title: 'IELTS Simulator',
    },
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Dashboard/views/IELTS/layout/IELTSLayout.vue'),
  },
  {
    path: '/ielts/speaking',
    name: 'SPEAKING',
    meta: {
      title: 'IELTS Speaking',
    },
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Dashboard/views/IELTS/views/IELTS.vue'),
  },
  {
    path: '/ielts/writing',
    name: 'WRITING',
    meta: {
      title: 'IELTS Writing',
    },
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Dashboard/views/IELTS/views/IELTS.vue'),
  },
];

export default routes;
