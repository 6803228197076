import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/pricing',
    name: 'Pricing',
    meta: {
      title: 'Pricing',
    },
    component: () => import('@/views/Pricing/PricingPlan/views/PricingPlan.vue'),
  },
];

export default routes;
