export const levels: { [key: string]: string } = {
  0: '',
  1: 'A1',
  2: 'A1+',
  3: 'A2',
  4: 'A2+',
  5: 'B1',
  6: 'B1+',
  7: 'B2',
  8: 'B2+',
  9: 'C1',
  10: 'C1+',
  11: 'C2',
  12: 'C2+',
};
