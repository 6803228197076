import { AxiosInstance } from 'axios';
import { PaymentsCheckout, PaymentsCheckoutRequest, PaymentsTransaction } from '@/views/Pricing/Payments/types';

export default (axios: AxiosInstance) => ({
  checkout: (request: PaymentsCheckoutRequest): Promise<PaymentsCheckout> =>
    axios.post('/payments/v2/checkout', request).then(({ data }: { data: PaymentsCheckout }) => data),

  transactionsGet: (transactionID: string): Promise<PaymentsTransaction> =>
    axios.get(`/payments/v2/transactions/${transactionID}`).then(({ data }: { data: PaymentsTransaction }) => data),

  transactionsList: (): Promise<PaymentsTransaction[]> =>
    axios.get('/payments/v2/transactions').then(({ data }: { data: PaymentsTransaction[] }) => data),

  subscription: (): Promise<PaymentsTransaction> =>
    axios.get('/payments/v2/subscription').then(({ data }: { data: PaymentsTransaction }) => data),

  subscriptionRedeemToken: (token: string): Promise<PaymentsTransaction> =>
    axios.get(`/payments/v2/redeem_free_subscription?token=${token}`),
});
