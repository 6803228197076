import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/pte',
    name: 'PTE',
    meta: {
      title: 'PTE Academic',
    },
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Dashboard/views/PTE/layout/PTELayout.vue'),
  },
];

export default routes;
