<template>
  <v-snackbar v-model="visible" v-bind="propsData" :key="id" style="z-index: 1000" :absolute="true">
    <div class="d-flex align-center justify-space-between">
      <div v-html="message"></div>
      <v-btn dark text @click.native="visible = false">Close</v-btn>
    </div>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { VSnackbar } from 'vuetify/lib';

@Component({
  components: {
    VSnackbar,
  },
})
export default class Snackbar extends Vue {
  @Prop({ type: Object, default: {} }) propsData!: { message: string; icon: string | null };

  @Prop({ type: Number, default: null }) id!: number;

  visible = false;

  mounted() {
    this.visible = true;
  }

  get message() {
    return Array.isArray(this.propsData.message) ? this.propsData.message.join('<br/>') : this.propsData.message;
  }

  @Watch('visible')
  onChangeVisible(newVal: boolean) {
    if (!newVal) this.$emit('remove', this.id);
  }
}
</script>
