import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/shared/:id',
    name: 'Shared',
    meta: {
      title: 'Report Example',
    },
    component: () => import('@/views/Report/SharedReport/views/SharedReport.vue'),
  },
  {
    path: '/shared/progress/:id',
    name: 'ProgressSharedView',
    props: true,
    component: () => import('@/views/Report/SharedReport/views/SharedProgress.vue'),
  },
];

export default routes;
