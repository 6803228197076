import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/reports/:id',
    name: 'ReportsItem',
    meta: {
      title: 'Report',
    },
    props: true,
    component: () => import('../views/Report.vue'),
  },
];

export default routes;
