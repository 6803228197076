import { RouteConfig } from 'vue-router';
import { isAuth } from '@/helpers/auth';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/views/Dashboard/layout/Layout.vue'),
    beforeEnter: (to, from, next) => {
      isAuth() ? next() : next({ path: '/auth' });
    },
    children: [
      {
        path: '/profile',
        name: 'Profile',
        meta: {
          title: 'Profile',
        },
        component: () => import('@/views/Profile/index.vue'),
      },
    ],
  },
];

export default routes;
