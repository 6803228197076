<template>
  <div class="message-box">
    <div class="timer-wrapper">
      <div class="timer-container">
        <div class="single-chart">
          <svg viewBox="0 0 48 48" class="circular-chart">
            <path
              class="circle-bg"
              d="M24 2
          a 16 16 0 0 1 0 42
          a 16 16 0 0 1 0 -42"
            />

            <path
              class="circle"
              :stroke-dasharray="`${132 - time * 12.8}, 128`"
              d="M24 2
          a 16 16 0 0 1 0 42
          a 16 16 0 0 1 0 -42"
            />
            <text x="24" y="28.5" class="percentage">{{ time }}</text>
          </svg>
        </div>
      </div>
      <div class="text-container">
        <div class="title">
          <span>Deleting the report...</span>
        </div>
        <div class="description">
          <span>The report will be deleted after the time has expired</span>
        </div>
      </div>
    </div>
    <div class="action-container">
      <button class="btn-cancel" @click="cancel">Cancel</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MessageBox extends Vue {
  @Prop() timestamp!: string;
  @Prop() id!: string;
  @Prop() action!: any;

  time = 10;

  timeOut;

  mounted() {
    this.tick();
  }

  tick() {
    this.timeOut = setTimeout(() => {
      this.time = 10 - ((+new Date() - this.timestamp) / 1000).toFixed(0);
      if (this.time >= 0) {
        this.tick();
        return;
      }
      this.$notificationService.resolve(this.id);
      this.action();
    }, 1000);
  }
  cancel() {
    this.$notificationService.resolve(this.id);
    clearTimeout(this.timeOut);
  }
}
</script>

<style scoped src="./MessageBox.scss" lang="scss" />
