import { RouteConfig } from 'vue-router';
import { isAuth } from '@/helpers/auth';
import reportItemRoutes from '@/views/Report/ReportItem/router';
import reportsRoutes from '@/views/Dashboard/views/Reports/router';
import progressRoutes from '@/views/Dashboard/views/Progress/router';
import ieltsRoutes from '@/views/Dashboard/views/IELTS/router';
import pteRoutes from '@/views/Dashboard/views/PTE/router';
import coursesRoutes from '@/views/Dashboard/views/Course/router';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('../layout/Layout.vue'),
    beforeEnter: (to, from, next) => {
      const { params } = from;
      isAuth() || to.path.includes('progress') ? next() : next({ path: '/auth', params });
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        meta: {
          title: 'Home',
        },
        component: () => import('../views/index/views/Dashboard.vue'),
      },
      ...reportsRoutes,
      ...reportItemRoutes,
      ...progressRoutes,
      ...ieltsRoutes,
      ...pteRoutes,
      ...coursesRoutes,
      {
        path: '/support',
        name: 'Support',
        meta: {
          title: 'Contact Us',
        },
        props: true,
        component: () => import('../views/index/views/Support.vue'),
      },
      {
        path: '/faq',
        name: 'Faq',
        meta: {
          title: 'FAQ',
        },
        props: true,
        component: () => import('../views/index/views/Faq.vue'),
      },
    ],
  },
];

export default routes;
